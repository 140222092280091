import React, { useEffect, useState } from "react";
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export default function Map(props) {

  const markerPin = require("../images/ping.png");
  const tampa = {lat: 27.9469,lng: -82.4672,};
  const imageCords = {
    lat: props.bayday.Latitude,
    lng: props.bayday.Longitude
  };

  const [markerPosition, setMarkerPosition] = useState(tampa);
  const [canDrag, setDrag] = useState(true);
  const [mapCenter, setMapCenter] = useState(tampa);
  const [result, setResult] = useState(0);
  const [polylinePath, setPolylinePath] = useState([]);


  //sending users cordinates to backend
  function handleCanDrag() {
    setDrag(false);
    setMapCenter(markerPosition);
    setResult((Math.sqrt((((imageCords.lat-markerPosition.lat) * 69)**2) + (((imageCords.lng-markerPosition.lng) * 56.4)**2))).toFixed(2))
    setPolylinePath([markerPosition, imageCords]);
  }

  useEffect(() => {
    let map;
    let marker;

    // Function to initialize the map
    window.initMap = () => {
      map = new window.google.maps.Map(document.getElementById("map"), {
        center: mapCenter,
        zoom: 12,
        minZoom: 12
      });

      marker = new window.google.maps.Marker({
        position: markerPosition,
        map: map,
        title: "choice",
        draggable: true,
        icon: markerPin,
      });
  
      //creating a marker for the image if the user is not guessing
      if (canDrag === false) {
        new window.google.maps.Marker({
          position: imageCords,
          map: map,
          title: "image",
          draggable: canDrag,
        })

        // Create the polyline
        new window.google.maps.Polyline({
          path: polylinePath,
          geodesic: true,
          strokeColor: "#000000", // You can choose your desired line color
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: map,
        });
      }

      marker.addListener("dragend", handleMarkerDragEnd);
    }

    function handleMarkerDragEnd(event) {
      const { latLng } = event;
      const lat = latLng.lat();
      const lng = latLng.lng();

      setMarkerPosition({ lat, lng });
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`;
    script.defer = true;
    script.async = true;
    document.head.appendChild(script);

    // We are excluding the following dependencies intentionally
    // because including them breaks the functionality of the component
    // as the google map will re-render with the pin at the location of 'tampa'
    // everytime the user moves the pin.
    //
    // imageCords
    // markerPosition
    // pin
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[canDrag]);

  return (
    <>
      {canDrag ? (
        <>
          <h1 className="title2">Drag the marker to where you think the picture was taken</h1>
          <div id="map" className="map"></div>
          <button onClick={handleCanDrag} className="ready-btn">Submit</button>
        </>
      ) : (
          <>
            <h1 className="title2">Come back in 24 hours for the next picture!</h1>
            <h1 className="title2">You were {result} miles close to pinning the bay!</h1>
            <div id="map" className="map"></div>
          </>
      )}
    </>

  );
}
