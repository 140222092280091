import React, {useState} from "react";
import Map from "./map";

export default function Ready(props) {

    const tampa = {lat: 27.9469,lng: -82.4672,};
    //will need to change this to the filepath for where the pictures are stored on the hosting provider
    const image = require(`../images/${props.bayday.Picture}`);
    //const image = require("../images/IMG_7400.webp"); //change to line above//////////////////////////////////////

    const [showMap, setShowMap] = useState(false);

    const handleButtonClick = () => {
        setShowMap(true);
    };

    return(
    <div className="map-container">
        {showMap ? (
          <>
            <Map bayday={props.bayday} marker={tampa} guessing={true} />
          </>
        ) : (
          <>
            {/* This img will be replaced with the new photo everyday/slideshow */}
            <h1 className="title2">Take a moment and think about where this picture was taken</h1>
            <img src={image} className="image" alt="test"/>

            <button className="ready-btn" onClick={handleButtonClick}>Ready?</button>
          </>
        )}
    </div>
    )
}