import React, {useState, useEffect} from "react";
import axios from "axios";
import Background from "./background.js";
import Ready from "./ready.js";
import Title from "./title.js";

export default function Home() {
    const [render, setrender] = useState(false); //change to false
    const [bayday, setbayday] = useState({});

  useEffect(()=>{
    axios
      .get(`https://ptb-api.vercel.app`)
      .then((res) => {
        setbayday(res.data);
        setrender(true);
      })
      .catch((err) => {
        console.log('Error from axios');
      });
  },[]);

  return(
    <>
        { render ? (
        <>
            <Background/>
            <Title/>
            <Ready bayday={bayday} />
        </>) : (
        <>
            <Background/>
            <Title/>
        </>  
        )}
    </>
  )
}