import React from "react";

export default function Title() {

    const today = new Date();
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const formattedDate = today.toLocaleDateString(undefined, options);

    return (
        <>
            <h1 className="title1">PinTheBay</h1>
            <h1 className="title2">{formattedDate}</h1>
        </>
    );
};